<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-shopping-cart-full"> </i> 教师考核 | 考核模板</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <el-button type="primary" @click="create()">新建绩效模板</el-button>
                        <el-button type="warning" @click="imp()">导入</el-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <el-input v-model="grid.sea.Func_Room_Name" placeholder="请输入关键字"
                                style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" @page-changed="loadPage">
                            <el-table-column type="index" label="序号" width="200" align="center" />
                            <el-table-column prop="Template_Name" label="模板名称" width="200" align="center" />
                            <el-table-column prop="Publish_User" label="题目数" />
                            <el-table-column label="创建时间">
                                <template slot-scope="scope">
                                    {{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}
                                </template>
                            </el-table-column>

                            <el-table-column prop="Publish_User_Name" label="创建人" />

                            <el-table-column label="操作" width="250">
                                <template slot-scope="scope">
                                    <el-button type="text" icon="el-icon-edit" @click="show(scope.row)">详情</el-button>
                                    <el-button type="text" icon="el-icon-edit"
                                        @click="rowEdit(scope.row)">编辑</el-button>
                                    <el-button type="text" icon="el-icon-delete"
                                        @click="rowDel(scope.row)">删除</el-button>

                                    <el-button type="text" @click="rowExport(scope.row)">导出</el-button>
                                </template>
                            </el-table-column>
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
        <Edit ref="edit"></Edit>
        <Import ref="imp" @on-saved="filter"></Import>
    </div>
</template>

<script>
import Edit from './edit'
import Wgrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'
import Import from './imp'
export default {
    components: { Wgrid, Edit, Import },
    data() {
        return {
            grid: {
                sea: {
                    PAGE_SIZE: 12,
                    PAGE_INDEX: 0,
                    Func_Room_Name: '',
                },
                ls: [],
                total: 0,
                loading: false
            },
        }
    },
    mounted() {
        this.get()
    },
    methods: {
        filter() {
            this.get()
        },
        loadPage() {
            this.grid.sea.PAGE_INDEX
        },
        get() {
            let self = this
            self.grid.loading = true
            this.whale.remote.getCollection({
                url: "/api/School/PFM/PfmTempalteSetting/GetPageList",
                data: { Key: self.grid.sea.Func_Room_Name },
                completed: function (its) {
                    self.grid.loading = false;
                    self.grid.ls = its
                    self.grid.total = its.length
                }
            })
        },
        show(r) {
            this.$refs.edit.init(r)
        },
        create() {
            this.$router.push('/perform/create')
        },
        rowEdit(r) {
            this.$router.push({ path: '/perform/create', query: { id: r.ID } })
        },

        rowDel(r) {
            let self = this;
            this.$confirm("真的要删除这条记录吗？", "撤消", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                self.whale.remote.getResult({
                    url: "/api/School/PFM/PfmTempalteSetting/Delete?Id=" + r.ID,
                    completed: function () {
                        self.$message.success('删除成功！')
                        self.get()
                    }
                })
            }).catch(() => { })
        },

        imp() {
            this.$refs.imp.init();
        },
        rowExport(r) {
            let self = this;
            this.grid.loading = true;
            // const formData = new FormData()
            // formData.append('Id',r.Id)
            this.whale.remote.getExportResult({
                url: "/api/School/PFM/PfmTempalteSetting/Export?Id=" + r.ID,
                // data: formData,
                completed: function (its) {
                    console.log('exls', its)
                    jsFileDownload(its, '导出绩效模板.xls')
                    // self.whale.downLoad(its.Data)
                    self.grid.loading = false;
                }
            })
        },
    },
}
</script>
<style lang="less" scoped></style>