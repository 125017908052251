<template>
    <div>
        <el-dialog :visible.sync="sta.show" title="导入新记录">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="form-group">
              <label><b>下载模板：</b></label>
              <a href="/api/files/template/cap_info_imp.xls" target="_blank">点击下载导入模板</a>
            </div> -->
                    <div class="form-group">
                        <el-upload drag action="#" :file-list="file_list" :limit="1" :http-request="upload_success"
                            :on-exceed="over_limit">
                            <i class="el-icon-upload" />
                            <div class="el-upload__text"><em>点击上传</em>,只能上传excel文件，且不超过10MB</div>
                        </el-upload>
                    </div>
                    <div class="form-group" v-if="err_guid != null">
                        <label><b></b></label>
                        <el-alert type="error" effect="dark" :closable="false" show-icon title="导入文件有错误">
                            <a style="color: #fff; font-size: 14px; text-decoration: underline"
                                @click="downFile(err_guid)">点击下载错误记录</a>
                        </el-alert>
                    </div>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="close">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">开始导入</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import jsFileDownload from 'js-file-download'
export default {
    data() {
        return {
            sta: {
                show: false,
                loading: false
            },
            file_list: [],
            file_guid: null,
            err_guid: null
        }
    },
    created() {

    },
    methods: {
        init() {
            this.sta = { show: true, loading: false }
            this.file_list = [];
            this.file_guid = null;
            this.err_guid = null;
        },
        over_limit() {
            this.whale.toast.err("只能上传一个文件");
        },
        upload_success(file) {
            console.log(file)
            let self = this;
            const formData = new FormData();
            formData.append("file", file.file);
            console.log(formData)
            this.whale.remote.import({
                url: 'api/School/fx/file/upload/excel',
                data: formData,
                completed: function (resp) {
                    // self.file_guid = resp.DATA.NAME
                    self.file_guid = resp.DATA.GUID
                    self.file_list.push[{ name: resp.DATA.NAME, url: resp.DATA.GUID }]
                }
            })
        },
        submit() {
            if (this.file_guid == null) {
                this.whale.toast.err("请在文件上传完成后，再导入");
                return;
            }
            let self = this;
            this.sta.loading = true;
            this.whale.remote.getResult({
                url: "/api/School/PFM/PfmTempalteSetting/Import?fGuid=" + self.file_guid,
                //   data: { FILE_GUID: self.file_guid },
                completed: function (m) {
                    console.log('导入', m)
                    self.sta.loading = false;
                    if (m != null) {
                        self.err_guid = m.DATA;
                        self.file_list = [];
                        self.file_guid = null;
                    } else {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                }
            })
        },
        downFile(guid) {
            this.whale.remote.getExport({
                url: '/api/School/FX/File/download/' + guid,
                completed: function (m) {
                    console.log('df', m)
                    jsFileDownload(m, '错误记录.xls')
                }
            })
        },
        close() {
            this.sta.show = false;
            this.$emit("on-saved");
        }
    }
}
</script>
<style>
.el-dialog__title {
    color: #fff
}
</style>